import { PageTypeHandle } from '~~/src/@types/type-handle';

export const printHeaderHeight: Partial<
  Record<PageTypeHandle, ReturnType<typeof Number>>
> = {
  [PageTypeHandle.OFFER]: 70,
  [PageTypeHandle.OFFICE]: 90,
};

export const printFooterHeight: Partial<
  Record<PageTypeHandle, ReturnType<typeof Number>>
> = {
  [PageTypeHandle.OFFER]: 130,
};
