<template>
  <PrintFrame v-if="renderPrint" :page-type-handle="typeHandle">
    <template #content>
      <slot />
    </template>
  </PrintFrame>

  <LayoutFrame v-else content-class="pb-3xl print:pb-0">
    <template #header>
      <Header />
    </template>
    <template #content>
      <Toast />
      <slot />
    </template>
    <template #footer>
      <Widget />
      <Footer />
    </template>
  </LayoutFrame>
</template>
<script setup lang="ts">
import LayoutFrame from '@/components/layout/LayoutFrame.vue';
import Header from '~~/src/components/layout/header/vdv/header.vue';
import Footer from '~~/src/components/layout/footer/vdv/footer.vue';
import Widget from '~~/src/components/components/widgets/vdv/widget-default.vue';
import Toast from '~~/src/components/toast/toast.vue';
import PrintFrame from '~/components/layout/PrintFrame.vue';
import { PageTypeHandle } from '~/@types/type-handle';
import useLoadCMSPage from '~/composables/useLoadCMSPage';

const typeHandle = ref<PageTypeHandle>(PageTypeHandle.PAGE);

const isPrintView = useMediaQuery('print');
const pagesWithDedicatedPrintView: PageTypeHandle[] = [
  // PageTypeHandle.OFFER, TODO: Check later if browser print this page correctly
  // PageTypeHandle.OFFICE, TODO: Check later if browser print this page correctly
];

const renderPrint = asyncComputed(async () => {
  if (!isPrintView.value) {
    return false;
  }

  const { pageData } = await useLoadCMSPage(useRoute().path);
  typeHandle.value = pageData.value.typeHandle;

  return pagesWithDedicatedPrintView.includes(typeHandle.value);
});
</script>
